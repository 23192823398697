import React, { useContext,Fragment } from 'react';

// import BookContextProvider from './contexts/BookContext';
import { BrowserRouter, Route, Switch,Redirect } from 'react-router-dom';
import Navbar from './components/NavBar';
// toast
import { ToastContainer,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";




import Test from './components/Test';

import './App.css';


import PageContextProvider from './contexts/PageContextProvider';
import Routes from './routers';



const App = () => {




const LoadRoutes = () => {

  // if (loading) {
  //   return (
  //     <h1>Loading</h1>
  //   )
  // }
  return localStorage.getItem("token") ? <AuthRoutes /> : <AppRoute />
}




  return (
<Fragment>
 <PageContextProvider>
    <BrowserRouter>
    <ToastContainer />
      <Navbar />
      <Routes/>
    </BrowserRouter>
    </PageContextProvider>
    </Fragment>
  );
};



export default App;



