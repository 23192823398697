import firebase from 'firebase';
import { toast } from 'react-toastify';
import api from './services/api';

export const initializeFirebase = () => {

  

  firebase.initializeApp({
    messagingSenderId: "535685846853"
  });

  // use other service worker
  // navigator.serviceWorker
  //   .register('registerServiceWorker.js')
  //   .then((registration) => {
  //     firebase.messaging().useServiceWorker(registration);
  // });

  
}

export const askForPermissioToReceiveNotifications = async (status) => {
  try {




    const messaging = firebase.messaging();

    await messaging.requestPermission();


    const token_push = await messaging.getToken();


    let token = localStorage.getItem('token');




    const params = new URLSearchParams();
          params.append('token_push', token_push);
          params.append('status', status);

          api.post('push-notification',params,
          {
            headers: {
              "Authorization" : `Bearer ${token}`
            }
          }
          ).then(res => {


            if (res.data.status_push=='active') {
               toast.success('Recebimento de push habilitado com sucesso!'); 
            } else {
               toast.error('Recebimento de push desativado com sucesso!'); 
            }
            console.log('user token: ', token_push);

          });


    return token;
  } catch (error) {
     
      alert(error)

  }
}
