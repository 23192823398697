import React, { useContext,useEffect } from 'react';


import { Route, Switch ,Redirect} from 'react-router-dom';
import Home from './../components/Home';
import Login from './../components/Login';
import Logout from './../components/Logout';
import Register from './../components/Register';
import QrCode from './../components/QrCode';
import Posts from './../components/Posts';
import Company from './../components/Company';

import Loyalties from './../components/Loyalties';
import Cupons from './../components/Loyalties/cupons';
import Points from './../components/Points/Points';
import PointsReplacement from './../components/Points/PointsReplacement';
import Cupons_Loyalties from './../components/Loyalties/cupons_loyalties';

import Dev from './../components/Dev';



const Routes = () => {


// AuthGuard
const authGuard = (Component) => () => {
    return localStorage.getItem("token") ? (
      <Component />
    ) : (
      <Redirect to="/login" />
    );
  };


//   Routes AuthRoutes
  function AuthRoutes () {
    return (
      <Switch>
            <Route exact path='/' render={authGuard(Home)} />
            <Route path='/logout' component={Logout} />
            <Route path='/login' component={Login} />
            <Route path='/dev' component={Dev} />
            {/* <Route path='/Test' component={Test} /> */}
            <Route path='/cadastro' component={Register} />
            <Route path="/home" render={authGuard(Home)}/> 
            <Route path="/qr-code" render={authGuard(QrCode)}/> 
            <Route path="/casas" render={authGuard(Company)}/> 
            <Route path="/posts" render={authGuard(Posts)}/> 
            <Route path="/pontos" render={authGuard(Points)}/> 
            <Route path="/trocar-pontos" render={authGuard(PointsReplacement)}/> 
            <Route path="/posts-push/:url_id"  render={authGuard(Posts)} />
            <Route path="/fidelidade" render={authGuard(Loyalties)}/> 
            <Route path="/cupons-emitidos" render={authGuard(Cupons_Loyalties)}/> 
            <Route path="/cupons/:url_id"  render={authGuard(Cupons)} />
            <Route path="*" component={() => <h1>Page not found</h1>} />
      </Switch>
    )
  };





//   if (loading) {
//     return (
      
//       <View style={{ flex: 1, justifyContent: 'center', alignItem: 'center' }}>
//         <ActivityIndicator size='large'  />
//       </View>
//     )
//   }




//   return user ? <AuthRoutes /> : <AuthRoutes />

return <AuthRoutes />
}

export default Routes;