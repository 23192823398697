import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import useInfiniteScroll from "./../../helpers/useInfinite"
import api from '../../services/api';
import Loading from '../../helpers/loading';
import { Link, useHistory } from 'react-router-dom';
import Error from '../../helpers/error';
import { PageContext } from './../../contexts/PageContextProvider';
import LoadingScreen from '../Loading'


const Loyalties = () => {
  const [loading, setLoading] = useState(true)
  const { user } = useContext(PageContext);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [current_page, setCurrent_page] = useState(1);
  const [isFetching, setIsFetching] = useInfiniteScroll(moreData);
  const history = useHistory();

  // set variable
  let token = localStorage.getItem('token');
  // let url = `companies`;

  function loadData() { 
      if (page <= lastPage) {
            let url = `companies?page=${page}`;
            api.get(url,
                {
                    headers: {"Authorization" : `Bearer ${token}`} 
                }
            ).then(res => {
                setLoading(false)
                setData([...data, ...res.data.data]);
                setLastPage(res.data.last_page);

                setPage(page+1)
                setIsFetching(false)
          });
      }
  }


  function ListLoyalties(url) {
    history.push(`/cupons/${url}`);
  }


  function moreData() {
    loadData()
  }

  useEffect(()=>{
  
    loadData()
  }, [])

  if (loading) {
    return (<LoadingScreen />);
  }


  if (data.length==0) {
      return (<Loading></Loading>);
  }

  if (!user.approved) {
      return (<Error message="Seu acesso ainda não foi autorizado!"></Error>);
  }

  if (!user.active) {
      return (<Error message="Seu acesso foi bloqueado, fale com seu gerente!"></Error>);
  }


  return (
    <>
        <div className='section'>
           <div className='container'>

              <div className="row">
                <div className="col s12">
                    <h6><b>Programa de Fidelidade</b></h6>
                    <p className="txt_p">Escolha a casa para gerar seus cupons.</p>
                </div>
                <div className="col s4 right-align">
                      {/* <a className="myButton "  onClick={() =>back()}><i className="material-icons mini-icon">replay</i> Voltar</a> */}
                </div>
              </div>

              {data.map((item, key) => (
              <div className="col s12 m8 offset-m2 l6 offset-l3"  key={key}>
                <div className="card-panel ">
                    <div className="row valign-wrapper">
                          <div className="col s4">
                                <img className="circle responsive-img" src={item.image} />
                          </div>
                          <div className="col s10 m-title">
                                <b>{item.name} </b> <br></br>
                                 Promoções ativas: <b>{item.count_event}</b> <br></br>
                                    {item.count_event
                                      ? <a className="myButton"  onClick={() => ListLoyalties(item.url)}><i className="material-icons mini-icon">qr_code</i> Pegar Cupons</a>
                                      : <div></div>
                                    }

                          </div>
                    </div>
                </div>
              </div>
                ))}

           </div>
        </div>
    </>
  );
};

export default Loyalties;