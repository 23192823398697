import React, { Fragment, useEffect,useContext } from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css';
import Error from '../../helpers/error';
// PageContext
import { PageContext } from './../../contexts/PageContextProvider';
import logo from '../../assets/logo.png'; // Tell webpack this JS file uses this image



// NoAuth
class NoAuth extends React.Component {
  render() {
    return (
        <ul id='slide-out' className='sidenav'>
            <li>
                <Link to='/' className='waves-effect sidenav-close'>
                <i className='material-icons'>home</i>Home
                </Link>
            </li>
            <li>
                <Link to='/login' className='waves-effect sidenav-close'>
                <i className='material-icons'>fingerprint</i>Login
                </Link>
            </li>
            <li>
                <Link to='/cadastro' className='waves-effect sidenav-close'>
                <i className='material-icons'>perm_identity</i>Cadastro
                </Link>
            </li>
        </ul>
    )
  }
}


// Auth
class Auth extends React.Component {
  render() {
    return (
        <ul id='slide-out' className='sidenav'>
            <li>
                <Link to='/home' className='waves-effect sidenav-close'>
                <i className='material-icons'>home</i>Home
                </Link>
            </li>
            {/* <li>
                <Link to='/login' className='waves-effect sidenav-close'>
                <i className='material-icons'>fingerprint</i>Login
                </Link>
            </li> */}
            {/* <li>
                <Link to='/cadastro' className='waves-effect sidenav-close'>
                <i className='material-icons'>perm_identity</i>Cadastro
                </Link>
            </li> */}
            <li>
                <Link to='/posts' className='waves-effect sidenav-close'>
                <i className='material-icons'>style</i>Programação
                </Link>
            </li>
            <li>
                <Link to='/fidelidade' className='waves-effect sidenav-close'>
                <i className='material-icons'>subtitles</i>Fidelidade
                </Link>
            </li>
            <li>
                <Link to='/cupons-emitidos' className='waves-effect sidenav-close'>
                <i className='material-icons'>qr_code</i>Meus Cupons
                </Link>
            </li>
            <li>
                <Link to='/pontos' className='waves-effect sidenav-close'>
                <i className='material-icons'>description</i>Meus Pontos
                </Link>
            </li>
            <li>
                <Link to='/trocar-pontos' className='waves-effect sidenav-close'>
                <i className='material-icons'>sync</i>Trocar de pontos
                </Link>
            </li>

            <li>
                <Link to='/casas' className='waves-effect sidenav-close'>
                <i className='material-icons'>business</i>Minhas Casas
                </Link>
            </li>
          <li>
              <Link to='/dev' className='waves-effect sidenav-close'>
              <img src="car.svg" alt="Car Icon"  width="30" height="30" /> <span className="linkBt">Ibingo Park</span>
              </Link>
          </li>

          <li>
              <Link to='/dev' className='waves-effect sidenav-close'>
              <img src="transfer.svg" alt="Car Icon"  width="30" height="30" /> <span className="linkBt">Ibingo Transfer</span>
              </Link>
          </li>


            <li>
                <Link to='/logout' className='waves-effect sidenav-close'>
                <i className='material-icons'>login</i>Sair
                </Link>
            </li>

            <li>

          <div className="row bg-logo">
                <div className="center-align">
                      Desenvolvido por:  <img className="logo-tl" src={logo} alt="Logo" />
                </div>
          </div>


            </li>

        </ul>

    )
  }
}



export default function components() {


const { user,checkUser,signOut } = useContext(PageContext);


useEffect(() => {
  // M.AutoInit();
    var elem = document.querySelector(".sidenav");
    M.Sidenav.init(elem, { edge: 'left',inDuration: 250 });

});


const isLoggedIn = checkUser;

 return (
<Fragment>
      <nav className='z-depth-0'>
        
        <div className='nav-wrapper'>
          <a href='/' className='brand'>
             <span>Ibingo</span>
          </a>

          <a
            href='#!'
            data-target='slide-out'
            className='right sidenav-trigger'
          >
             <i className='material-icons'>menu</i>
          </a>


          {isLoggedIn ? (
               <span></span>
            ) : (
              <span></span>
            )}

            <ul className='right hide-on-med-and-down'>
              <li>
                <Link to='/home'>Home</Link>
              </li>

              <li>
                <Link to='/login'>Login</Link>
              </li>

              <li>
                <Link to='/cadastro'>Cadastro</Link>
              </li>
            </ul>

        </div>
      </nav>
      {isLoggedIn ? (
        <Auth></Auth>
      ) : (
        <NoAuth></NoAuth>
      )}
    </Fragment>
 );
}









