import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
 export default class Dev extends React.Component {
  //other logic
    render() {
     return(
        <div className="pre-loading">
                Em Desenvolvimento.
        </div>
     );
    }
 }