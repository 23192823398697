import React from 'react';

const Loading = () => {



  return (
        <div>

         </div>
          
          
          
          );

  };
  

  export default Loading;


