import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import useInfiniteScroll from "./../../helpers/useInfinite"
import api from '../../services/api';
import Loading from '../../helpers/loading';
import Error from '../../helpers/error';
import { toast } from 'react-toastify';
import { PageContext } from './../../contexts/PageContextProvider';
import LoadingScreen from '../Loading'
import { Link, useHistory } from 'react-router-dom';
import Video from 'react-responsive-video'
import {useParams} from "react-router-dom";

import Moment from 'react-moment';
import 'moment-timezone';



const Posts = () => {


  let { url_id } = useParams();
  const { user } = useContext(PageContext);
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [current_page, setCurrent_page] = useState(1);
  const [isFetching, setIsFetching] = useInfiniteScroll(moreData);
  const history = useHistory();

  const [errorUser, setErrorUser] = useState(false);


  // set variable
  let token = localStorage.getItem('token');
  // let url = `companies`;

  function loadData() { 

      if (page <= lastPage) {

            // checkk url id
            if (url_id) {
              var url = `posts-push/${url_id}?page=${page}`;
            } else {
              var url = `posts?page=${page}`;
            }

            api.get(url,
                {
                    headers: {"Authorization" : `Bearer ${token}`} 
                }
            ).then(res => {

                setData([...data, ...res.data.data]);
                setLastPage(res.data.last_page);

                setPage(page+1)
                setIsFetching(false)
                if (res.data.total ==0) {
                      setTotal(res.data.total);
                      toast.error('Nenhuma programação disponível'); 
                }
          }).catch(e => {
             toast.error(e.response.data.message); 
             history.push(`/home`);
          });
      }
  }


  function ListPosts(url) {
    history.push(`/posts`);
  }


  function moreData() {
    loadData()
  }

  useEffect(()=>{
    setTimeout(() => setLoading(false), 500)
      loadData()
  }, [])



  if (loading) {
    return (  <LoadingScreen />);
  }



  if (loading) {
    if (!user.approved) {
      return (<Error message="Seu acesso ainda não foi autorizado!"></Error>);
  }

  if (!user.active) {
      return (<Error message="Seu acesso foi bloqueado, fale com seu gerente!"></Error>);
  }

  if (data.length==0) {
    return (<Error message="Nenhuma postagem disponível!"></Error>);
  }

  }





  return (
    <>
        <div className='section'>
           <div className='container2'>
            <div className="row">
                <div className="col s12 ">
                    <br></br>
                    <br></br>
                    <br></br>
                  {total===0
                    ?  <h6 className="ptxt"><b>Aguarde novas publicações!</b></h6>
                    :  <h6 className="ptxt" ><b>Programação {total}</b></h6>
                  }
                </div>
            </div>
          {data.map((item, key) => (
            <div className="row" key={key}>
                <div className="col s12 m7">
                  <div className="card">
                  <div className="card-action card-border">
                    <a className="bt-cp btn-floating btn-large waves-effect waves-light "> <img className="circle responsive-img" src={item.companies_file} /></a>
                      <b className="titName"> {item.name}</b>
                      <br></br>
                      <span className="time"> </span>
                    </div>
                    <div className="card-image">
                         <div className="card-txt">
                            <h6><b>{item.title}</b></h6>
                             <p className="mtxt">{item.description}</p>
                             <div className="right-align">
                             <i className="material-icons min-icon">schedule</i>
                            <Moment format="DD/MM">
                                 {item.date_publication_start}
                            </Moment> - 
                            <Moment date={item.date_publication_start} format="hh:mm"  />
                            </div>
                         </div>
                             {item.image
                                ?   <img src={item.image}></img>
                                :  <div></div>
                              }

                              {item.video
                                ?  <Video controls={true} loop={true} mp4={item.video} />
                                :  <h6></h6>
                              }
                    </div>
                  </div>
                </div>
              </div>
            ))}

           </div>
        </div>
    </>
  );
};

export default Posts;