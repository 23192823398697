import React, {useState } from 'react'

import { useHistory } from 'react-router-dom';
import QrReader from 'react-qr-reader'
// import { QRCode } from "react-qr-svg";








const QrCode = () => {

  const [result, setResult] = useState([]);


//   const [qrcode, setQrCode] = useState('123456');
//   const [checkQrCode, setCheckQrCode] = useState(false);


  const history = useHistory();





  async function handleScan(data) {

    setResult();
 
    if (data) {
       alert(data)
       history.push('/print/123456');

    }

  }
  



  async function handleError(err) {
    console.error(err)

  }  




    return (
        <div>
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
        <p>{result}</p>

   

          <button className="waves-effect waves-light btn" onClick={handleScan} >
              TESTE
       </button>     

    
       

      </div>




     
    );
  };
  
  export default QrCode;

