import React, { useContext,useEffect } from 'react';
import { PageContext } from './../../contexts/PageContextProvider';
import { useHistory } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const Logout = () => {

    const MySwal = withReactContent(Swal);
    // check user
    const { signOut } = useContext(PageContext);
    const history = useHistory();

    const logout = e => {

        MySwal.fire({
            title: 'Tem certeza que deseja sair do Ibingo?',
            text: "Essa ação necessita logar novamente com  cpf e senha.",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#000000',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, eu quero',
            cancelButtonText: "Cancelar",
        }).then((result) => {

            if (result.value) {
                    MySwal.fire(
                        'Logout',
                        'Logout efetuado com sucesso!',
                        'success'
                    )
                 signOut()
                 history.push('/login');
            } else {
                history.push('/home');

            }
        });
    };

    useEffect(() => {
        logout();
    }, [])

    return (

            <div className='section'>
                  <div className="container">
                      
                  </div>
              </div>






    );
}
export default Logout;