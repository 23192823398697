import React, { useState, useEffect,useContext } from "react";
import api from '../../services/api';
import Loading from '../../helpers/loading';
import { Link, useHistory } from 'react-router-dom';
import "materialize-css/dist/css/materialize.min.css";
import { toast } from 'react-toastify';
import { formatToNumber } from 'brazilian-values';

export const formatNumber = inputNumber => {

    return(inputNumber);
  };


const Points = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([]);
  const [qrcode, setQrCode] = useState('');



    // total
    const handleChangePoint = e => {

            var n=(e.target.value);

            // if(isNaN(n))
            // {
            // }
            // else
            // {   
            
            //     setTotalPoint(n)
            // }
            setTotalPoint(n)
       
    };


  // forms
  const [totalPoint, setTotalPoint] = useState('');
  const [idCompany, setIdCompany] = useState('');
  const [balance, setBalance] = useState('');
  const [formError, setFormError] = useState(true);

  async function loadData() { 
    let token = localStorage.getItem('token');
    let url = `points`;
    api.get(url,
          {
              headers: {"Authorization" : `Bearer ${token}`} 
          }
      ).then(res => {
          setData([...data, ...res.data.data]);
          setLoading(false)
    });
  }

  function replacementPoint(idCompany,balance) {   
    var elem= document.querySelector('.modal');
    var instance = M.Modal.init(elem);
    instance.open();

    setIdCompany(idCompany)
    setBalance(balance)
    
  }


  async function handleSubmit(e) {
    e.preventDefault();

      if (!totalPoint) {
          toast.error('Informe um valor');   
          return ;
      }

      // if (totalPoint>balance) {
      //   toast.error('Seu saldo é insuficiente!');   
      //   return ;
      // }

      let token = localStorage.getItem('token');
      const params = new URLSearchParams();
      params.append('totalPoint', totalPoint);
      params.append('idCompany', idCompany);
      // post api
      api.post('points',
        {
          totalPoint: totalPoint, 
          idCompany: idCompany
        },
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      ).then(res => {
          toast.success(res.data.message);
          history.push(`/pontos`);

      }).catch(error => {
          toast.error(error.response.data.message);
      });
  

  }
  useEffect(()=>{
      setTimeout(() => setLoading(false), 500)
        loadData()
      }, [])

  if (data.length==0) {
      return (<Loading></Loading>);
  }

  // if (!user.approved) {
  //     return (<Error message="Seu acesso ainda não foi autorizado!"></Error>);
  // }

  // if (!user.active) {
  //     return (<Error message="Seu acesso foi bloqueado, fale com seu gerente!"></Error>);
  // }

  return (
    <>
<div className='section'>
    <div className='container'>

           <div className="row">
                <div className="col s8">
                    <h6><b>Trocar Pontos</b></h6>
                </div>
              </div>
        <div id="modal1" className="modal">
            <div className="modal-content">
                <p>Trocar Pontos<br></br> <b>Seu saldo: {balance} pontos.</b>  </p>
                <form onSubmit={handleSubmit}>
                    <div className='input-field'>
       
                        <input
                        type="number"
                        id='totalPoint'
                        name='totalPoint'
                        value={totalPoint}
                        onChange={handleChangePoint}
                        placeholder='Informe a quantidade!'
                        // className='validate'
                        required
                        /> 
                        <label className='active' htmlFor='totalPoint'>
                        Valor
                        </label>
                    </div>
                    <div className='input-field center'>
                        <button
                            disabled={!formError}
                            type='submit'
                            className='btn waves-effect waves-light amber darken-2'
                            >
                        <i className='material-icons right'>sync</i> Trocar Pontos
                        </button>
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <a className="modal-action modal-close waves-effect waves-green btn-flat">OK</a>
            </div>
        </div>
        {/* list */}
        <table className="striped ">
            <thead>
                <tr>
                    <th>Casa</th>
                    <th>Total de Pontos</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, key) => (
                <tr key={key}>
                    <td>{item.name}</td>
                    <td><b>{item.balance}</b>  </td>
                    <td>
                        <div className="row center-align">
                            <a className="myButton "  onClick={() => replacementPoint(item.id_company,item.balance)}><i className="material-icons mini-icon">sync</i> Trocar</a>
                        </div>
                    </td>
                </tr>
                ))}
            </tbody>
        </table>
    </div>
</div>
    </>
  );
};

export default Points;