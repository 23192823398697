import axios from 'axios';

const api = axios.create({
    baseURL: 'https://cdn.ibingo.club/api'
    // baseURL: 'http://localhost/api',
    // baseURL: 'https://cdn.ibingo.info/api'
})
export const version = 'Versão 3.0.5';
export default api;

