import React, { useContext,useEffect,useState } from 'react';
import { PageContext } from './../../contexts/PageContextProvider';
import { QRCode } from "react-qr-svg";
import { Link, useHistory } from 'react-router-dom';
import { askForPermissioToReceiveNotifications } from '../../push-notification';
import LoadingScreen from '../Loading'
import Toggle from 'react-toggle'
import api from '../../services/api';
import logo from '../../assets/logo.png'; // Tell webpack this JS file uses this image



const Header = () => {


    const [state, setState] = useState(true);
    const [loading, setLoading] = useState(true)
    const [push, setPush] = useState(false)


    const history = useHistory();
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    // check user
    const { user } = useContext(PageContext);


    const onInstalPWA = evt => {
     evt.preventDefault(); 
        if (!promptInstall) {
          return;
        }
        promptInstall.prompt();
      };


      function toggle() {
        setState(!state);

        if (state) {

          askForPermissioToReceiveNotifications(false)

        } else {
          askForPermissioToReceiveNotifications(true)
        }



      }




 async function checkUserHome() {



  let token = localStorage.getItem('token');

  await api.get('auth/user', { headers: {"Authorization" : `Bearer ${token}`} })
  .then(response => {
    if (response.data && response.data.user) {
      // Assuming 'user' is the property that contains user data
      setState(true);
    } else {
      setState(false);
    }
  })
  .catch(error => {

    localStorage.removeItem('token');
    history.push(`/login`);
    setState(false);
  });

  }
    useEffect(() => {
      setTimeout(() => setLoading(false), 1000)
      checkUserHome()
        
    }, []);




    if (loading) {
      return (  <LoadingScreen />);
    }
  


    return (
      <>
      {loading === false ? (
      <div className='section'>
        <div className="container">
            <div className="row">
              <div className="col s12 m">


                  { user.approved  && !loading  ?     (
                  <div className="card-panel">
                    {user.active ? (
                    <div className="center-align">
                        <b> {user.name}</b>, seu acesso foi autorizado.
                          Seus dados estão protegidos<br></br> por criptografia. 🔐

                    </div>
                    ) : (
                    <div>
                        <b> {user.name}</b>, seu acesso foi bloqueado temporariamente, entre em contato com seu gerente!

                    </div>
                    )}
                  </div>
                  ) : (


                  <div className="card-panel teal">
                    <span className="white-text">
                    <b>{user.name},</b><br></br>
                      Apresente este <b>QR Code</b> para o gerente autorizar o seu acesso.
                    </span>
                  </div>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col s12 m">
                  <div className="card-panel ">
                     <QRCode
                      bgColor="#FFFFFF"
                      fgColor="#000000"
                      level="H"
                      value={user.uuid}
                      />
                    <div className="center-align">
                        Este é seu <b>QR CODE</b> de identificação.
                   </div>
                  </div>
              </div>
            </div>
            <div className="row">

              <div className="card-panel">
                  <div className="">
                 <div >
                 <span  className="myButton2" onClick={toggle}>
                    <b>  {state ? <h6><span><b>Receber Push  </b>👍 </span></h6> :<h6> <span>NÃO! 👎</span></h6>}</b>
                  </span>
                  <div className="center-align">
                       Autorizar recebimento de notificações.
                  </div>
                 </div>


                  </div>
              </div>
            </div>


        </div>





        <div className="row bg-logo">
              <div className="center-align">
                    Desenvolvido por:  <img className="logo-tl" src={logo} alt="Logo" />
                    
              </div>
             
        </div>
        
        <div className="row center-align">
            Esta plataforma não realiza jogos nem recebe apostas remuneradas.<br></br><br></br><br></br>
        </div>

      </div>

      ) : (
        <LoadingScreen />
      )}
      </>

    );
}
export default Header;