import React, { useState,useContext,useEffect } from 'react';
import MaskedInput from 'react-text-mask'
import { toast } from 'react-toastify';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import { cpfMask } from '../../helpers/mask'
import { QRCode } from "react-qr-svg";

import { PageContext } from './../../contexts/PageContextProvider';
import { isCPF} from 'brazilian-values';

// load api

import api from '../../services/api';
import { version } from '../../services/api';

const MySwal = withReactContent(Swal)


const Login = () => {

const [app_version, setVersion] = useState('Versão 2.0.3');

const { authUser } = useContext(PageContext);


const history = useHistory();
// set error Form
const [formError, setFormError] = useState(true);


const [forms, setForms] = useState({
    cpf: '',
    password: ''
});

const handleChange = e => {
  setForms({ ...forms, 
         [e.target.name]: e.target.value 
        });
};

const [cpf, setCPF] = useState({cpf: ''});


const handleChangeCPF = e => {
    setCPF({cpf:cpfMask(e.target.value)});
    setForms({ ...forms, 
      cpf: cpfMask(e.target.value)
     });

};


// notify
const notify = (message) =>toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
  });


  async function handleSubmit(e) {
    e.preventDefault();
   
   
    if (!isCPF(forms.cpf)) {
        toast.error('O CPF informado é inválido!');   
        return ;
    }

    
    try {

        
            const params = new URLSearchParams();
                  params.append('cpf', forms.cpf);
                  params.append('password', forms.password);

            // post api
            const response = await api.post('auth/login', params);

                              localStorage.setItem('token', response.data.BearerToken);
                              localStorage.setItem('uuid', response.data.uuid);
                              // localStorage.setItem('uuid', JSON.stringify(response.data.uuid));
                              toast.success( response.data.message);

                              // MySwal.fire(
                              //   response.data.message,
                              //   response.data.message_text,
                              //   'success'
                              // )
                              authUser();

                               history.replace('/home');
    } catch (error) {


        if (error) {
            setFormError(true);
            var errorsList= error.response.data.errors
  
  
            console.log(errorsList)
            var Msg='';
            Object.keys(errorsList).map((key) => {
                Msg= Msg + errorsList[key] + '<br/>';
            });
  
            toast.error('Foram encontrados alguns erros!');
  
            MySwal.fire({
              icon: 'error',
              title: "OPS!", 
              html: Msg,  
              confirmButtonText: "OK", 
            });


        }

       
    }

  }

  useEffect(() => {

    console.log(version)
       setVersion(version);   
  }, []);
  return (
          <div className='section'>
              <div className='container'>
                  <h6>Login</h6>
                  <form onSubmit={handleSubmit}>

                      <div className='input-field'>
                          <input
                              type='text'
                              id='cpf'
                              name='cpf'
                              maxLength='14'
                              value={cpf.cpf}
                              onChange={handleChangeCPF}
                              placeholder='Informe seu CPF'
                              className='validate'
                              required
                              />
                          <label className='active' htmlFor='cpf'>
                          CPF
                          </label>
                      </div>
                      <div className='input-field'>
                          <input
                              type='password'
                              id='password'
                              name='password'
                              value={forms.password}
                              onChange={handleChange}
                              placeholder='Sua senha'
                              className='validate'
                              required
                              />
                          <label className='active' htmlFor='name'>
                          Senha de acesso
                          </label>
                      </div>
                      <div className='input-field center'>
                          <button
                              disabled={!formError}
                              type='submit'
                              className='btn waves-effect waves-light amber darken-2'
                              >
                          <i className='material-icons right'>fingerprint</i> Logar
                          </button>

                      <div className='input-field center'>
                         {app_version} <br></br>
                           Esta plataforma não realiza jogos nem recebe apostas remuneradas.
                      </div>
                     

                      </div>
                  </form>
              </div>
          </div>
  );
};

export default Login;
