import React from 'react';
import Toggle from 'react-toggle'
import api from '../../services/api';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import Error from '../../helpers/error';
import Loading from '../Loading'




class Company extends React.Component {

    constructor(props) {
      super(props)
      this.state = {
          toggles: [
        ],
        validateError: false,
        loading: true,
      }
    }





    async updateCompany(updatedToggles) { 
        let token = localStorage.getItem('token');

        var roles=updatedToggles;
        const data = {
            roles
          };

          try {
            await api.post('companies-post', data, {
              headers: {
                Authorization: `Bearer ${token}`,
              }
            })
          } catch (err) {
            // alert(err);
          }

   }


   async loadData() { 
         let token = localStorage.getItem('token');
         let url = `companies-list`;
      await api.get(url,
            {
                headers: {"Authorization" : `Bearer ${token}`} 
            }
        ).then(res => {
            if (res.data.total ==0) {
                  setTotal(res.data.total);
            }
            this.setState(Object.assign({}, this.state, {toggles: res.data}));
      }).catch(e => {

        toast.error(e.response.data.message); 
        this.setState(Object.assign({}, this.state, {validateError: true}));

        // history.push(`/home`);

      })

      ;

    }


    handleChange(id) {


        const updatedToggles = []
        for(const toggle of this.state.toggles) {
            if(toggle.id === id) {
                     updatedToggles.push(Object.assign({}, toggle, {value: !toggle.value}))

            } else {
                     updatedToggles.push(toggle)
            }
        }

      this.setState(Object.assign({}, this.state, {toggles: updatedToggles}));
     //update user
      this.updateCompany(updatedToggles)
    }


    componentDidMount() {
        this.loadData()
        setTimeout(() =>  this.setState(Object.assign({}, this.state, {loading: false})), 500)
      }


    render() {



      if (this.state.loading) {
        return (<Loading></Loading>);
    }

      if (this.state.validateError) {
          return (<Error message="Seu acesso ainda não foi autorizado!"></Error>);
      }


      return (
        <div className='section'>
            <div className='container'>
                    <table className="striped ">
                        <thead>
                        <tr>
                            <th className="f-table">Minhas Casas</th>
                            <th></th>

                            <th>Ativar</th>
                        </tr>
                        </thead>

                        <tbody>
                        { this.state.toggles.map((item, key) => (
                        <tr key={key}>
                            <td>{item.name}</td>
                            <td></td>
                            <td> <label>
                            <Toggle
                                checked={item.value}
                                onChange={(e) => this.handleChange(item.id)}  />
                            </label></td>
                        </tr>
                        ))}
                        </tbody>
                    </table>

            </div>
        </div>
      )
    }
  }
  export default Company;