
import React, { useState, useEffect, createContext } from 'react';
export const PageContext = createContext();
import api from './../services/api';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';

import { Redirect } from 'react-router-dom'


const Error = () => {
    return (
        <div classNames="section">
            <div classNames="container">
                <br></br>
                <br></br>
                <br></br>
                <h5>Acesso não autorizado</h5>
            </div>
         </div>
      );
  };


  export const PageContextProvider = (props) => {

    // get user
    const [user, setUser] = useState({
        'name': '*****',
        'uuid': '*****',
        'aproved': false,
        'active': false,
    });

    const [autenticate, setAutenticate] = useState(false);


    // get user
    const [checkUser, setcheckUser] = useState(false);
    const [permissionUser,setPermissionUser] = useState(false);

    // ckeck aproved
    const [aproved, setAproved] = useState(false);
    const [active, setActive] = useState(false);


    async function ckeckUser() {

        var token = localStorage.getItem('token');

        if (token==null || token=='undefined') {

        } else {
            await api.get('auth/user', {
                headers: {"Authorization" : `Bearer ${token}`} 
                }).then(response => {
                    setUser(response.data);
                    setAproved(response.data.approved);
                    setActive(response.data.active);
                    setAutenticate(true);
                    // console.log(response.data)
                })
        }


        // console.log(token)

        if (token==null) {
            setcheckUser(false);
        } else {
            setcheckUser(true);
        }

    }

    function authUser() {
        ckeckUser()
    }


    // checo permission
    function checkPermission() {

         permissionUser(true);
    }


    function signOut() {
        localStorage.removeItem('token');
        localStorage.removeItem('uuid');
        setcheckUser(false);
    }


    async function signIn(data) {

        setLoading(true);
        setSigned(true);


        // let token={'user': 'Fabricio','token': '79869387456389473'};
        // localStorage.setItem('token', JSON.stringify(token));

    }

    useEffect(() => {
        ckeckUser();

      }, [])

    return (
        <PageContext.Provider value={{ 
            user: user,
            checkUser,
            authUser,
            signOut,
            checkPermission,
            permissionUser,
            active,
            autenticate
        }}>
          {props.children}
        </PageContext.Provider>
    );
}
export default PageContextProvider;
