import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import useInfiniteScroll from "./../../helpers/useInfinite"
import api from '../../services/api';
import Loading from '../../helpers/loading';
import { Link, useHistory } from 'react-router-dom';
import {useParams} from "react-router-dom";
import { QRCode } from "react-qr-svg";
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import Error from '../../helpers/error';
import { PageContext } from './../../contexts/PageContextProvider';
import LoadingScreen from '../Loading'

const Cupons = () => {

  const [loading, setLoading] = useState(true)
  const { user } = useContext(PageContext);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [qrcode, setQrCode] = useState('');
  const [company, setCompany] = useState([]);


  const [lastPage, setLastPage] = useState(1);
  const [current_page, setCurrent_page] = useState(1);
  const [isFetching, setIsFetching] = useInfiniteScroll(moreData);
  const history = useHistory();



  // set variable
  let token = localStorage.getItem('token');
  // let qrcode='123456';

  // let url = `companies`;

  function loadData() {

      if (page <= lastPage) {
            let url = `cupons?page=${page}`;
            try {

                  api.get(url,
                      {
                          headers: {"Authorization" : `Bearer ${token}`} 
                      }
                  ).then(res => {
                      setData([...data, ...res.data.data]);
                      setCompany(res.data);
                      setLastPage(res.data.last_page);
                      setPage(page+1)
                      setIsFetching(false)

                      setLoading(false)
                });
      } catch (error) {

      // alert(error)
  
        // console.log(error)
  
        // setFormError(true);
        //     var errorsList= error.response.data.errors
  
        //     var Msg='';
        //     Object.keys(errorsList).map((key) => {
        //         Msg= Msg + errorsList[key] + '<br/>';
        //     });
  
        //     toast.error('Foram encontrados alguns erros!');
  
        //     MySwal.fire({
        //       icon: 'error',
        //       title: "OPS!", 
        //       html: Msg,  
        //       confirmButtonText: "OK", 
        //     });
      }
      }
  }


  function back() {   
    history.push(`/fidelidade`);
   
  }


  function getCupom(id) {   
    
    setQrCode(id);
    var elem= document.querySelector('.modal');
    var instance = M.Modal.init(elem);
    instance.open();
    
  }


  function moreData() {    
    loadData()
  }

  useEffect(()=>{
    setTimeout(() => setLoading(false), 1000)
    loadData()
  }, [])


  if (loading) {
    return (  <LoadingScreen />);
  }


  if (!user.approved) {
      return (<Error message="Seu acesso ainda não foi autorizado!"></Error>);
  }

  if (!user.active) {
      return (<Error message="Seu acesso foi bloqueado, fale com seu gerente!"></Error>);
  }
  if (data.length==0) {
    return (<Error message="Nenhum cupom foi gerado!" ></Error>);
  }

  return (
    <>
       {loading === false ? (
        <div className='section'>
           <div className='container'>
              <div className="row">
                <div className="col s8">
                    <h6><b>Meus Cupons: {company.total}</b></h6>
                </div>
                <div className="col s4 right-align">
                      <a className="myButton "  onClick={() =>back()}><i className="material-icons mini-icon">replay</i> Voltar</a>
                </div>
              </div>
              {data.map((item, key) => (
              <div className="col s12"  key={key}>
                <div className="card-panel">

                <div className="row">
                    <div className="col s8">
                            <b>Código:</b> <br></br>
                           {item.code}<br></br>
                           {item.name}<br></br>
                           {/* {item.uuid} */}
                           <br></br>
                           <br></br>
                           <span className="code">*Em {item.code_date} às {item.code_time}.</span>
                    </div>

                    <div className="col s1">
                                <QRCode
                                    bgColor="#FFFFFF"
                                    fgColor="#000000"
                                    level="Q"
                                    style={{width: 80 }}
                                    value={item.uuid}
                                />
                    </div>

                </div>
                </div>
              </div>
                ))}
           </div>
        </div>
   ) : (
    <LoadingScreen />
  )}
  </>
  );
};

export default Cupons;