import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
 export default class Loading extends React.Component {
  //other logic
    render() {
     return(

    <div className="pre-loading">
        <Loader
            type="ThreeDots"
            color="#000000"
            height={50}
            width={50}
        />
    </div>


     );
    }
 }