import React, { useState,useEffect } from 'react';
import { cpfMask } from '../../helpers/mask'
import MaskedInput from 'react-text-mask'
import { toast } from 'react-toastify';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import { isCPF, isPhone,isDate,formatToCPF } from 'brazilian-values';
// https://www.npmjs.com/package/react-google-recaptcha
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();
import Toggle from 'react-toggle';

// load api
import api from '../../services/api';
const MySwal = withReactContent(Swal)


const Register = () => {

const history = useHistory();
// set error Form
const [formError, setFormError] = useState(true);
const [recaptcha, setRecaptcha] = useState('');
const [keySite, setkeySite] = useState('');

const [forms, setForms] = useState({
    name: '',
    email: '',
    cpf: '',
    date_birth: '',
    phone_cell: '',
    password: '',
    number_card: ''
    
});

const handleChange = e => {
  setForms({ ...forms, 
         [e.target.name]: e.target.value
        });
};

const [cpf, setCPF] = useState({cpf: ''});

// format cpf
const handleChangeCPF = e => {
    setCPF({cpf:cpfMask(e.target.value)});
    setForms({ ...forms, 
      cpf: formatToCPF(e.target.value)
     });
};

// set phone cell
const [phone_cell, setPhoneCell] = useState({phone_cell: ''});
const handleChangePhoneCell = e => {
        setForms({ ...forms, 
          phone_cell: e.target.value
        });
};

// notify
const notify = (message) =>toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
  });


async function loadConfig() { 





    await api.get(`config`).then(res => {
        setkeySite(res.data.google_recaptcha_site_key)

    }).catch(e => {

    });


 



}


  async function handleSubmit(e) {
    e.preventDefault();



    if (!isCPF(forms.cpf)) {
        toast.error('O CPF informado é inválido!');   
        return ;
    }

    // if (!isDate(forms.date_birth)) {
    //     toast.error('Data de nascimento inválida!');   
    //     return ;
    // }

    if (!isPhone(forms.phone_cell)) {
        toast.error('Número de celular');   
        return ;
    }

    // setFormError(false);

    try {


            const params = new URLSearchParams();
                  params.append('name', forms.name);
                  params.append('email', forms.email);
                  params.append('cpf', forms.cpf);
                  params.append('date_birth', forms.date_birth);
                  params.append('phone_cell', forms.phone_cell);
                  params.append('password', forms.password);
                  params.append('recaptcha', recaptcha);
                  params.append('number_card', forms.number_card);


            // post api
            const response = await api.post('auth/signup', params);

                //  setFormError(false);

                              localStorage.setItem('token', response.data.BearerToken);
                              localStorage.setItem('uuid', response.data.uuid);
                              // localStorage.setItem('uuid', JSON.stringify(response.data.uuid));


                              MySwal.fire(
                                response.data.message,
                                response.data.message_text,
                                'success'
                              )  
                              
                              window.location.reload();

                              
                              
                            //   const timer = setTimeout(() => {
                            //          history.push('/home');
                            //   }, 1000);


                            
                             
    } catch (error) {



      console.log(error)

      setFormError(true);
          var errorsList= error.response.data.errors

          var Msg='';
          Object.keys(errorsList).map((key) => {
              Msg= Msg + errorsList[key] + '<br/>';
          });

          toast.error('Foram encontrados alguns erros!');

          MySwal.fire({
            icon: 'error',
            title: "OPS!", 
            html: Msg,  
            confirmButtonText: "OK", 
          });
    }

  }


  function onChange(value) {
    setRecaptcha(value);

  }



  useEffect(()=>{
    loadConfig()
    }, [])



    let token = localStorage.getItem('token');

    if (token) {
        history.push('/home');
    } else {
    }

  return (
          <div className='section'>
              <div className='container'>
                  <h6>Abrir conta.</h6>
                  <p> Seus dados serão protegidos por criptografia. 🔐</p>
                  <form onSubmit={handleSubmit}>
                      <div className='input-field'>
                          <input
                              type='text'
                              id='name'
                              name='name'
                              value={forms.name}
                              onChange={handleChange}
                              placeholder='Seu Nome'
                              className='validate'
                              required
                              />
                          <label className='active' htmlFor='name'>
                          Seu nome
                          </label>
                      </div>
                      <div className='input-field'>
                          <input
                              type='email'
                              id='email'
                              name='email'
                              value={forms.email}
                              onChange={handleChange}
                              placeholder='Informe seu e-mail'
                              className='validate'
                              />
                          <label className='active' htmlFor='email'>
                          E-mail
                          </label>
                      </div>
                      <div className='input-field'>
                          <input
                              type='text'
                              id='cpf'
                              name='cpf'
                              maxLength='14'
                              value={cpf.cpf}
                              onChange={handleChangeCPF}
                              placeholder='Informe seu CPF'
                              className='validate'
                              required
                              />
                          <label className='active' htmlFor='email'>
                          CPF
                          </label>
                      </div>
                      <div className='input-field'>
                          <input
                              type='date'
                              id='date_birth'
                              name='date_birth'
                              value={forms.date_birth}
                              onChange={handleChange}
                              placeholder='Informe sua data de nascimento'
                              className='validate'
                              required
                              />
                          <label className='active' htmlFor='date_birth'>
                          Data de nascimento
                          </label>
                      </div>
                      <div className='input-field center'>
                          <MaskedInput placeholder='Número do Whatsapp'  mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}   onChange={handleChangePhoneCell}/>
                          <label className='active' htmlFor='date_birth'>
                          Celular 
                          </label>
                      </div>
                      <div className='input-field'>
                          <input
                              type='password'
                              id='password'
                              name='password'
                              value={forms.password}
                              onChange={handleChange}
                              placeholder='Informe uma senha'
                              className='validate'
                              required
                              />
                          <label className='active' htmlFor='name'>
                            Senha de acesso
                          </label>
                      </div>

                      <div className='input-field'>
                          <input
                              type='text'
                              id='number_card'
                              name='number_card'
                              value={forms.numer_card}
                              onChange={handleChange}
                              placeholder='Número do cartão fidelidade antigo'
                              className='validate'
                              />
                          <label className='active' htmlFor='number_card'>
                              Número do cartão
                          </label>
                      </div>

                      <div className='input-field center'>
                        {keySite ? (
                            <div>
                             <ReCAPTCHA
                                onChange={onChange}
                                sitekey={keySite}
                                 />
                            </div>
                          ) : (<div>
                                </div>

                        )}  
                      </div>

                      <button
                              disabled={!formError}
                              type='submit'
                              className='btn waves-effect waves-light amber darken-2'
                              >
                          <i className='material-icons right'>fingerprint</i> Solicitar Acesso
                          </button>

                  </form>
              </div>
          </div>
  );
};

export default Register;
