import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import useInfiniteScroll from "./../../helpers/useInfinite"
import api from '../../services/api';
import Loading from '../../helpers/loading';
import { Link, useHistory } from 'react-router-dom';
import {useParams} from "react-router-dom";
import { QRCode } from "react-qr-svg";
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import Error from '../../helpers/error';
import { PageContext } from './../../contexts/PageContextProvider';
import LoadingScreen from '../Loading'
import { toast } from 'react-toastify';


const Cupons = () => {
  const [loading, setLoading] = useState(true)
  const { user } = useContext(PageContext);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [qrcode, setQrCode] = useState('');
  const [company, setCompany] = useState([]);

  const [lastPage, setLastPage] = useState(1);
  const [current_page, setCurrent_page] = useState(1);
  const [isFetching, setIsFetching] = useInfiniteScroll(moreData);
  const history = useHistory();

  let { url_id } = useParams();

  // set variable
  let token = localStorage.getItem('token');
  // let qrcode='123456';

  // let url = `companies`;

  function loadData() { 


      if (page <= lastPage) {
            let url = `loyalties/${url_id}?page=${page}`;
            try {
                  api.get(url,
                      {
                          headers: {"Authorization" : `Bearer ${token}`} 
                      }
                  ).then(res => {
                      setData([...data, ...res.data.data]);
                      setCompany(res.data);
                      setLastPage(res.data.last_page);
                      setPage(page+1)
                      setIsFetching(false)
                }).catch((e) => {
                    toast.error(e.response.data.message);
                    
               });

              } catch (error) {

            
                
              }
      }
  }


  function back() {
    history.push(`/fidelidade`);
  }


  function getCupom(id) {
    setQrCode(id);
    var elem= document.querySelector('.modal');
    var instance = M.Modal.init(elem);
    instance.open();

  }


  function moreData() {
    loadData()
  }

  useEffect(()=>{
    setTimeout(() => setLoading(false), 500)
    loadData()


    
  }, [])

  if (data.length==0) {
      return (<Loading></Loading>);
  }

  if (!user.approved) {
      return (<Error message="Seu acesso ainda não foi autorizado!"></Error>);
  }

  if (!user.active) {
      return (<Error message="Seu acesso foi bloqueado, fale com seu gerente!"></Error>);
  }


  return (
    <>
        <div className='section'>
            <div id="modal1" className="modal">
              <div className="modal-content">
                 <p>Apresente este <b>QR-Code</b> para impressão do cupom. </p>
                <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                value={qrcode}
            />
              </div>
              <div className="modal-footer">
                <a className="modal-action modal-close waves-effect waves-green btn-flat">OK</a>
              </div>
            </div>
           <div className='container'>

              <div className="row">
                <div className="col s8">
                    <h6><b>{company.company_name}</b></h6>
                </div>
                <div className="col s4 right-align">
                      <a className="myButton "  onClick={() =>back()}><i className="material-icons mini-icon">replay</i> Voltar</a>
                </div>
              </div>


              {data.map((item, key) => (
              <div className="col s12"  key={key}>
                <div className="card-panel">

                <div className="row">
                    <div className="col s8">
                         <b>Promoção:</b> <br></br>
                           {item.name}<br></br>
                    </div>
                    <div className="col s2">
                          <b>Validade: </b><br></br>{item.date_publication_end} <br></br>{item.date_publication_end_time}
                    </div>
                </div>
                <div className="row center-align">

                     <a className="myButton "  onClick={() => getCupom(item.qr_code)}><i className="material-icons mini-icon">qr_code</i> Gerar Cupons</a>
                </div>
                </div>
              </div>
                ))}

           </div>
        </div>
     
    </>
  );
};

export default Cupons;