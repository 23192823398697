import React from 'react';

const Error = (props) => {
    return (
        <div className="section">
            <div className="container">
                <ul className="collapsible">
                        <li>
                            <div className="collapsible-header">
                            <i className="material-icons">block</i>
                            {props.message}
                            </div>
                        </li>
                </ul>
            </div>
         </div>
      );
  };

  export default Error;


