import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { initializeFirebase } from './push-notification';
import "materialize-css/dist/css/materialize.min.css";
import { toast } from 'react-toastify';

import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
import myLogo from "./assets/logo.png";
 /* eslint-disable */




 function PWA2() {
  
  return (
    <div  className="pwa fixarRodape" >
    <button type="button" className="myButtonPwa "  >
        <i className="material-icons m-icone">settings_cell</i>  Instalar aplicativo Ibingo
    </button>
 </div>
  );
}




 function PWA() {
    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

    const handleClick = () => {
      pwaInstall({
        title: "Instalar aplicativo Ibingo",
        logo: myLogo,
        // features: (
        //   <ul>
        //     <li>Receba nossa programação.</li>
        //     <li>Cartão Fidelidade</li>
        //   </ul>
        // ),
        // description: "Aplicativo Ibingo",
      })
        .then(() => 
            toast.success('Instalação iniciada, será adicionado um atalho na sua tela, por favor aguarde a instalação!')
        )
        .catch(() => 
             toast.error('Instalação cancelada')
        );
    };
    return (
      <div>
        {supported() && !isInstalled() && (
        <div  className="myButtonPwa pwa fixarRodape" >
            <button type="button" className="myButtonPwa" onClick={handleClick} >
                <i className="material-icons m-icone">settings_cell</i>  Instalar aplicativo Ibingo
            </button>
         </div>
        )}
      </div>
    );
  }


  ReactDOM.render(
    <ReactPWAInstallProvider enableLogging>
      <App/>
      <PWA/>
    </ReactPWAInstallProvider>,
    document.querySelector("#root")
  );
registerServiceWorker();
initializeFirebase(); 


